/* eslint react/no-array-index-key: 0 */
import React, {
  FunctionComponent,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import { Box, TransparentButton } from 'mdlkit';

interface Responsive {
  breakpoint: number;
  itemsToShow: number;
}

interface Props {
  responsive?: Responsive[];
  arrowPath?: string;
  pageButtonColor?: string;
  children: ReactNode;
}

const MainContainer = styled(Box)`
  width: 100%;
`;

const SlideContiner = styled(Box)`
  display: flex;
  justify-content: space-between;
`;

const ContentContainer = styled(Box)`
  width: 100%;
  overflow: hidden;
`;

const ChildrenContainer = styled(Box)<{
  translate?: number;
  transition: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateX(${({ translate }) => translate}%);
  ${({ transition }) => transition && 'transition: transform 500ms ease 0s'}
`;

const StyledTransparentButton = styled(TransparentButton)`
  &:first-child {
    padding-right: 15px;
  }
  &:last-child {
    padding-left: 15px;
  }
`;

const LeftArrow = styled('img')`
  transform: rotate(180deg);
`;

const RightArrow = styled('img')``;

const ChildWrapper = styled(Box)<{ responsive: Responsive[] }>`
  ${({ responsive }) =>
    responsive.map(
      ({ breakpoint, itemsToShow }) =>
        `@media(min-width: ${breakpoint}px){
            min-width: ${100 / itemsToShow}%;
        }`
    )}

  min-width: 100%;
  display: flex;
  justify-content: center;
`;

const PageButtonsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

const PageButton = styled(TransparentButton)<{
  isActive: boolean;
  pageButtonColor?: string;
}>`
  width: 11px;
  height: 11px;
  margin: 0px 5px;
  background: ${({ pageButtonColor }) => pageButtonColor};
  ${({ isActive }) => !isActive && 'opacity: 40%;'}
  border: 0;
  border-radius: 2px;
`;

const ResponsiveCarousel: FunctionComponent<Props> = ({
  responsive = [{ breakpoint: 0, itemsToShow: 1 }],
  arrowPath,
  pageButtonColor,
  children,
}: Props) => {
  const [childrenLength, setChildrenLength] = useState<number>(0);
  const [translate, setTranslate] = useState<number>(0);
  const [translateFactor, setTranslateFactor] = useState<number>(0);
  const [transition, setTransition] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(0);
  const [newsChildren, setNewsChildren] = useState<ReactNode[]>([]);
  const [itemsShowed, setItemsShowed] = useState<number>(0);

  const handleNext = () => {
    setCurrent(current + 1);
    setTransition(true);
    setButtonDisabled(true);
    setTranslate(translate - translateFactor);

    if (current === childrenLength - 1) {
      setTimeout(() => {
        setTransition(false);
        setTranslate(-(translateFactor * itemsShowed));
      }, 500);
      setCurrent(0);
    }
    setTimeout(() => {
      setButtonDisabled(false);
    }, 500);
  };

  const handlePrev = () => {
    setCurrent(current - 1);
    setTransition(true);
    setButtonDisabled(true);
    setTranslate(translate + translateFactor);

    if (itemsShowed - 1 === -current) {
      setTimeout(() => {
        setTransition(false);
        setTranslate(-(translateFactor * childrenLength));
      }, 500);
      setCurrent(childrenLength - 1 + current);
    }
    setTimeout(() => {
      setButtonDisabled(false);
    }, 500);
  };

  const createConfigurations = () => {
    const width = window.innerWidth;
    const correspondedBreakpoint = responsive.reduce(
      (acc, curr) => {
        if (width >= acc.breakpoint && width < curr.breakpoint) {
          return acc;
        }
        return curr;
      },
      { breakpoint: 0, itemsToShow: 1 }
    );
    const { itemsToShow } = correspondedBreakpoint;
    const factor = 100 / itemsToShow;
    setTranslateFactor(factor);
    setItemsShowed(itemsToShow);

    const arrayChildren = React.Children.toArray(children);

    const firstClones = arrayChildren.slice(0, itemsToShow);
    const lastClones = arrayChildren.slice(
      arrayChildren.length - itemsToShow,
      arrayChildren.length
    );
    setNewsChildren([...lastClones, ...arrayChildren, ...firstClones]);
    setTranslate(-(factor * itemsToShow));
    setCurrent(0);
  };

  useEffect(() => {
    createConfigurations();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', createConfigurations);
    setChildrenLength(React.Children.count(children));

    return () => {
      window.removeEventListener('resize', createConfigurations);
    };
  });

  return (
    <MainContainer>
      <SlideContiner>
        {childrenLength > itemsShowed && (
          <StyledTransparentButton
            disabled={buttonDisabled}
            onClick={() => handlePrev()}
          >
            <LeftArrow src={arrowPath} />
          </StyledTransparentButton>
        )}
        <ContentContainer>
          <ChildrenContainer translate={translate} transition={transition}>
            {newsChildren.map((child, index) => (
              <ChildWrapper key={`child-${index}`} responsive={responsive}>
                {child}
              </ChildWrapper>
            ))}
          </ChildrenContainer>
        </ContentContainer>
        {childrenLength > itemsShowed && (
          <StyledTransparentButton
            disabled={buttonDisabled}
            onClick={() => handleNext()}
          >
            <RightArrow src={arrowPath} />
          </StyledTransparentButton>
        )}
      </SlideContiner>
      {childrenLength > itemsShowed && (
        <PageButtonsContainer>
          {[...Array(childrenLength)].map((_, i) => {
            return (
              <PageButton
                isActive={i === current || i === current + childrenLength}
                pageButtonColor={pageButtonColor}
                key={`page-${i}`}
              ></PageButton>
            );
          })}
        </PageButtonsContainer>
      )}
    </MainContainer>
  );
};

ResponsiveCarousel.defaultProps = {
  arrowPath: '/static/white-arrow.png',
  pageButtonColor: 'white',
};

export default ResponsiveCarousel;
