import React, { MouseEvent, FunctionComponent } from 'react';
import styled from 'styled-components';
import { CloseButton } from 'mdlkit';

export const StyledCloseButton = styled(CloseButton)``;

interface ToastCloseProps {
  closeToast?(event: MouseEvent<HTMLButtonElement>): void;
}

export const ToastClose: FunctionComponent<ToastCloseProps> = ({
  closeToast,
}: ToastCloseProps) => <StyledCloseButton onClick={closeToast} />;

export default ToastClose;
