import styled from 'styled-components';
import { darken } from 'polished';
import { Button, mq } from 'mdlkit';

const RoundedButton = styled(Button)<{
  backgroundColor?: string;
  borderRadius?: string;
}>`
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.tertiary};
  border-radius: ${({ borderRadius }) => borderRadius || '40px'};

  ${mq({
    padding: ['3px 20px', '9.5px 38px'],
  })}

  &:hover,
  &:focus {
    background-color: ${({ backgroundColor, theme }) =>
      darken(0.2, backgroundColor || theme.colors.tertiary)};
  }
`;

RoundedButton.defaultProps = {
  color: 'orange',
};

export default RoundedButton;
