import React, { FunctionComponent } from 'react';
import Link from 'next/link';
import { Hyperlink, HyperlinkProps } from 'mdlkit';

interface Unknown {
  [key: string]: {} | null | undefined;
}

interface UrlObject {
  pathname: string;
  query?: string | null | Unknown;
}
type HyperlinkPropsWithoutHref = Omit<HyperlinkProps, 'href'>;

type NextHyperlinkProps = {
  href: string | UrlObject;
  onClick?(): void;
  target?: string;
} & HyperlinkPropsWithoutHref;

const NextHyperlink: FunctionComponent<NextHyperlinkProps> = ({
  href,
  ...rest
}: NextHyperlinkProps) => (
  <Link href={href} passHref>
    <Hyperlink {...rest} />
  </Link>
);

export default NextHyperlink;
