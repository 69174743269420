import React, {
  ReactNode,
  FunctionComponent,
  useState,
  useEffect,
  createContext,
} from 'react';
import { Patient } from '../../interfaces/patient';
import getPatientAsync from '../../api/patients/getPatientAsync';
import { getUserId, getToken } from '../../utils/auth';

interface ContextProps {
  patient: Patient;
}

export const PatientContext = createContext<Partial<ContextProps>>({});
export const PatientConsumer = PatientContext.Consumer;

interface Props {
  children?: ReactNode;
}

export const PatientProvider: FunctionComponent<Props> = ({
  children,
}: Props) => {
  const [patient, setPatient] = useState<Patient | undefined>(undefined);

  const getPatient = async () => {
    const userId: number = getUserId();
    const token: string = getToken();

    if (userId && token) {
      const { patient: pat } = await getPatientAsync(userId, token);
      setPatient(pat);
    }
  };

  useEffect(() => {
    getPatient();
  }, []);

  return (
    <PatientContext.Provider value={{ patient }}>
      {children}
    </PatientContext.Provider>
  );
};
