import React, { FunctionComponent } from 'react';

interface Props {
  message: string;
}

const CallCustomerService: FunctionComponent<Props> = ({ message }: Props) => (
  <div
    dangerouslySetInnerHTML={{
      __html: `${message} Please call customer service at <a href="tel:1-800-400-6354">(800) 400-6354</a>.`,
    }}
  />
);

export default CallCustomerService;
