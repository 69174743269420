import React, { FunctionComponent } from 'react';
import Link from 'next/link';
import { Button, ButtonProps } from 'mdlkit';

type NextLinkButtonProps = {
  href: string;
  onClick?(): void;
} & ButtonProps;

const NextLinkButton: FunctionComponent<NextLinkButtonProps> = ({
  href,
  ...rest
}: NextLinkButtonProps) => (
  <Link href={href} passHref>
    <Button as="a" {...rest} />
  </Link>
);

export default NextLinkButton;
