import React, { FunctionComponent, useState, useRef } from 'react';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';
import { Box, TransparentButton } from 'mdlkit';
import { Video } from '../../interfaces/www';

const Container = styled(Box)`
  display: flex;
`;

const VideoWrapper = styled(Box)<{ isVisible: boolean }>`
  width: 100%;
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;

const ImageWrapperButton = styled(TransparentButton)<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  width: 100%;
  height: 100%;
  line-height: 0;
  img {
    width: 100%;
  }
`;

const StyledVideo = styled('video')`
  width: 100%;
  margin: 0;
  padding: 0;
`;

interface Props extends Video, SpaceProps {
  height?: string;
}

const VideoContainer: FunctionComponent<Props> = ({
  imageSrc,
  imageAlt,
  videoSrc,
  height,
  ...rest
}: Props) => {
  const [visibleVideo, setVisibleVideo] = useState<boolean>(false);
  const videoRef = useRef(null);
  const handleImageClick = () => {
    setVisibleVideo(true);
    // @ts-ignore
    videoRef.current.play();
  };
  return (
    <Container {...rest}>
      <VideoWrapper isVisible={visibleVideo}>
        <StyledVideo controls ref={videoRef} height={height}>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
      </VideoWrapper>
      <ImageWrapperButton onClick={handleImageClick} isVisible={!visibleVideo}>
        <img src={imageSrc} alt={imageAlt} />
      </ImageWrapperButton>
    </Container>
  );
};

export default VideoContainer;
