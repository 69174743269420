import { getToken } from '../../utils/auth';
import apiFetch from '../core/apiFetch';
import { checkStatus, handleError } from '../../utils/api';
import { mapPatient } from './helpers';
import { Patient } from '../../interfaces/patient';

interface PatientType {
  patient: Patient | undefined;
}

const getPatientAsync = async (
  id: number,
  token?: string
): Promise<PatientType> => {
  const url = `api/v2/patients/${id}`;

  try {
    const jwt = token || getToken();
    const response = await apiFetch(jwt, url, {
      method: 'GET',
    });

    await checkStatus(response);

    const { patient_profile: patient } = await response.json();

    return { patient: mapPatient(patient) };
  } catch (error) {
    handleError('unexpected status - get v2 patient', error);
    return { patient: undefined };
  }
};

export default getPatientAsync;
