import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { Flex } from 'mdlkit';

const StyledFlex = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.borderGray};

  > a {
    align-self: center;
  }
`;

interface Props {
  children: ReactNode;
}

const AppCardFooter: FunctionComponent<Props> = ({ children }: Props) => (
  <StyledFlex column align="center" pt="md" mt="md">
    {children}
  </StyledFlex>
);

export default AppCardFooter;
