import styled from 'styled-components';
import { darken } from 'polished';
import { Button } from 'mdlkit';

const SquaredButton = styled(Button)<{ backgroundColor?: string }>`
  border-radius: 0px;
  border: 2px solid #fff;
  font-weight: ${({ theme }) => theme.fontWeights.bolder};
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.tertiary};

  &:hover,
  &:focus {
    background-color: ${({ backgroundColor, theme }) =>
      `${darken(0.2, backgroundColor || theme.colors.tertiary)}`};
  }
`;

export default SquaredButton;
