import React, { FunctionComponent, ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';
import { Box, TransparentButton } from 'mdlkit';
import { WidthProps, SpaceProps, BackgroundColorProps } from 'styled-system';

const Container = styled(Box)`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
`;

const translationTop = keyframes`
  0% {
    transform: translate(-50%, -40%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
`;

const Wrapper = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;
  max-height: 85vh;
  background: ${({ theme, backgroundColor }) =>
    !backgroundColor && theme.colors.white};
  border-radius: 0px 25px;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 25px 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  animation: 0.5s ease ${translationTop};
  animation-fill-mode: forwards;
`;

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const StyledTransparentButton = styled(TransparentButton)<{
  backgroundColor?: string;
}>`
  position: absolute;
  right: 0;
  top: 0;
  padding: 15px;
  border-radius: 0px 25px 0px 0px;
  z-index: 1;
  background: ${({ theme, backgroundColor }) =>
    backgroundColor && (theme.colors[backgroundColor] || backgroundColor)};
  cursor: pointer;
  img {
    width: 15px;
    animation: 0.5s ease ${rotation};
    animation-fill-mode: forwards;
  }
`;

const translationRight = keyframes`
  0% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(0px);
  }
`;

const ChildrenWrapper = styled(Box)`
  animation: 0.5s ease ${translationRight};
  animation-fill-mode: forwards;
`;

interface Props extends WidthProps, SpaceProps, BackgroundColorProps {
  handleDisplayModal(value: number | null | boolean): void;
  children: ReactNode;
  closeIconPath?: string;
  backgroundIcon?: string;
}

const Modal: FunctionComponent<Props> = ({
  closeIconPath,
  backgroundIcon,
  handleDisplayModal,
  children,
  ...rest
}: Props) => (
  <Container>
    <Wrapper {...rest}>
      <StyledTransparentButton
        backgroundColor={backgroundIcon}
        onClick={() => handleDisplayModal(null)}
      >
        <img src={closeIconPath} alt="close icon" />
      </StyledTransparentButton>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  </Container>
);

Modal.defaultProps = {
  closeIconPath: '/static/behavioral-health/x-icon.svg',
  backgroundIcon: 'transparent',
};

export default Modal;
