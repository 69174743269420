import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { SpaceProps } from 'styled-system';
import { Box, Text, TransparentButton, Heading, mq } from 'mdlkit';
import ReactMarkdown from 'react-markdown';
import { Modal, VideoContainer } from '.';
import { FAQ } from '../../interfaces/www';

const Container = styled(Box)`
  width: 100%;
`;

const ItemButton = styled(TransparentButton)`
  display: flex;
  align-items: center;
  min-height: 80px;
  margin-bottom: 14px;
  text-align: left;
  cursor: pointer;
  img {
    height: 41px;
    margin-right: 15px;
  }

  &:hover,
  &:focus {
    filter: invert(52%) sepia(84%) saturate(3348%) hue-rotate(349deg)
      brightness(99%) contrast(92%);
  }
`;

interface Props extends SpaceProps {
  FAQList: FAQ[];
}

const FAQContainer: FunctionComponent<Props> = ({
  FAQList,
  ...rest
}: Props) => {
  const [showModal, setShowModal] = useState<number | null>(null);
  const handleModal = (toShow) => {
    setShowModal(toShow);
  };
  return (
    <Container {...rest}>
      {FAQList &&
        FAQList.map(({ iconSrc, question }, index) => (
          <ItemButton
            key={`${question}-item`}
            onClick={() => handleModal(index)}
          >
            <img src={iconSrc} alt="question icon" />
            <Text
              fontSize={['18px', null, null, 3]}
              fontWeight="bold"
              lineHeight="26px"
            >
              <ReactMarkdown>{question}</ReactMarkdown>
            </Text>
          </ItemButton>
        ))}
      {showModal !== null && (
        <Modal
          handleDisplayModal={handleModal}
          backgroundIcon="black"
          width={['100%', '100%', '690px']}
        >
          <ModalContent {...FAQList[showModal]} />
        </Modal>
      )}
    </Container>
  );
};

const ModalContainer = styled(Box)`
  ${mq({ padding: ['65px 24px 65px 19px', '65px 70px'] })};
`;

const StyledImage = styled(`img`)`
  height: 75px;
  ${mq({ marginRight: ['18px', '40px'] })};
  filter: invert(52%) sepia(84%) saturate(3348%) hue-rotate(349deg)
    brightness(99%) contrast(92%);
`;

const StyledHeading = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes[4]}px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.tertiary};
`;

const StyledMarkdown = styled(ReactMarkdown)`
  font-size: ${({ theme }) => theme.fontSizes[2]}px;
  line-height: 24px;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.tertiary};
  }

  p {
    &:not(:first-child) {
      margin-top: 25px;
    }
  }
`;

const BodyWrapper = styled(Box)`
  display: flex;
  margin-top: 35px;
`;

const ModalContent: FunctionComponent<FAQ> = ({
  iconSrc,
  question,
  answer,
  video,
}: FAQ) => (
  <ModalContainer>
    <StyledHeading as="h4">{question}</StyledHeading>
    <BodyWrapper>
      <StyledImage src={iconSrc} alt={`${question}-icon`} />
      <Box>
        <StyledMarkdown>{answer}</StyledMarkdown>
        {video && (
          <VideoContainer
            imageSrc={video.imageSrc}
            imageAlt={video.imageAlt}
            videoSrc={video.videoSrc}
            mt="30px"
          />
        )}
      </Box>
    </BodyWrapper>
  </ModalContainer>
);

export default FAQContainer;
