import {
  PatientApi,
  PatientUpdateApi,
  Patient,
} from '../../interfaces/patient';
import { formatDate } from '../../utils/shared/normalize';

export const mapPatient = (patient: PatientApi): Patient => ({
  id: patient.id,
  lastEligibilityCheckId: patient.last_eligibility_check_id,
  firstName: patient.first_name,
  lastName: patient.last_name,
  zip: patient.zip,
  dobDay: patient.dobDay,
  dobMonth: patient.dobMonth,
  dobYear: patient.dobYear,
  stateId: patient.state_id,
  gender: patient.gender,
});

export const mapPatientUpdateApi = (patient: Patient): PatientUpdateApi => ({
  first_name: patient.firstName,
  last_name: patient.lastName,
  zip: patient.zip,
  birthdate: formatDate(
    `${patient.dobMonth}/${patient.dobDay}/${patient.dobYear}`,
    'iso'
  ),
  gender: patient.gender,
});
